import React from "react";
import "../divBorder.css"; // Import the new styles

export const DivBorder = ({ hide }) => {
  return (
    <div
      className="divBorderContainer"
      style={{ display: hide ? "none" : "flex" }}
    >
      <div className="divSquare gradient-1"></div>
      <div className="divSquare gradient-2"></div>
      <div className="divSquare gradient-3"></div>
      <div className="divSquare gradient-4"></div>
      <div className="divSquare gradient-5"></div>
      <div className="divSquare gradient-6"></div>
      <div className="divSquare gradient-7"></div>
      <div className="divSquare gradient-8"></div>
      <div className="divSquare gradient-9"></div>
    </div>
  );
};
